import { gql, useQuery } from '@apollo/client';
import { keyBy, map, uniq } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PassInfo, { newPassInfo } from '../../../../common_lib_front/types/passInfo';
import { newVehicleInfo } from '../../../../common_lib_front/types/vehicleInfo';
import { backendClient } from '../../../../common_lib_front/utilities/BackendAPI';
import { GET_PRICE_INFO } from '../registerVehicle/registerVehicleRequests';

const GET_PASS = gql`
  query GetPassesByRegistration($registrationId: String!) {
    getPassesByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        passId
        passType
        passInfoId
        userId
        number
        externalCredentialNumber
        startDate
        endDate
        # validThrough
        status
        url
        shared
        addons
      }
    }
  }
`;

interface IGetPassResponse {
  getPassesByRegistration: {
    success: boolean;
    error: string | null;
    data: PassInfo[] | null;
  };
}

interface IUseOrderSummary {
  passes: PassInfo[];
  errorMsg: string;
}

const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId) {
      success
      error
      data {
        vehicleId
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        isRental
        licensePlateState
        primaryDriverName
        licensePrimaryDriver
      }
    }
  }
`;

export default function useOrderSummary(): IUseOrderSummary {
  const { registrationId } = useParams<{ registrationId: string }>();
  const [errorMsg, setErrorMsg] = useState<string | null>();
  const [passes, setPasses] = useState<any>([]);
  const { data } = useQuery<IGetPassResponse>(GET_PASS, {
    fetchPolicy: 'network-only',
    variables: {
      registrationId,
    },
    onError: e => {
      setErrorMsg(e.message);
    },
    onCompleted: async d => {
      if (!d.getPassesByRegistration.success) {
        setErrorMsg(d.getPassesByRegistration.error);
      }

      const res = d?.getPassesByRegistration?.data?.map((p: any) => newPassInfo(p)) || [];
      Promise.all(
        res.map((element, idx: number) =>
          backendClient
            .query({
              query: GET_VEHICLE,
              variables: { passId: element.passId },
            })
            .then(v => {
              res[idx].vehicle = newVehicleInfo(v.data.getVehicle.data);
            })
            .catch(e => {
              console.log(e);
            }),
        ),
      ).then(() => {
        // setPasses(res);
        // setCompanyInfo(newCompanyInfo(d.getUserInfo.data));
      });

      // Get passes' prices by passInfoId
      const passInfoIds = uniq(map(res, 'passInfoId'));
      try {
        const priceInfoRes = await Promise.all(
          map(passInfoIds, (id: string) =>
            backendClient.query({
              query: GET_PRICE_INFO,
              variables: { passInfoId: id },
            }),
          ),
        );
        // Set price info to passes by passInfoId
        const priceInfoMap = keyBy(
          map(priceInfoRes, 'data.getPriceInfo.data'),
          'passInfoId',
        );
        res?.forEach((item: any) => {
          // eslint-disable-next-line no-param-reassign
          item.priceInfo = priceInfoMap[item.passInfoId];
        });
      } catch (error: any) {
        console.log(error);
      }
      setPasses(res);
    },
  });

  return {
    passes: passes || [],
    errorMsg: errorMsg || '',
  };
}
