import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  MutationHookOptions,
  OperationVariables,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useState } from 'react';
import { TFunction } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { newPassInfo } from '../../../../common_lib_front/types/passInfo';
import { newVehicleInfo } from '../../../../common_lib_front/types/vehicleInfo';
import { backendClient } from '../../../../common_lib_front/utilities/BackendAPI';
import { passFormInput } from '../../../../components/residentPassForm/passForm';
import {
  DELETE_PASS_AND_VEHICLE,
  DELETE_PASS_AND_VEHICLE_RES,
  DELETE_PASS_AND_VEHICLE_VARS,
  EDIT_PASS,
  EDIT_PASS_RES,
  EDIT_PASS_VARS,
  EDIT_STEP_NUMBER,
  EDIT_STEP_NUMBER_RES,
  EDIT_STEP_NUMBER_VARS,
  EDIT_VEHICLE,
  EDIT_VEHICLE_RES,
  EDIT_VEHICLE_VARS,
  GET_VEHICLE,
  GET_VEHICLE_RES,
  GET_VEHICLE_VARS,
  REGISTER_PASS,
  REGISTER_PASS_RES,
  REGISTER_PASS_VARS,
  REGISTER_VEHICLE,
  REGISTER_VEHICLE_RES,
  REGISTER_VEHICLE_VARS,
  STEP1_QUERY,
  STEP1_QUERY_RES,
  STEP1_QUERY_VARS,
} from './registerVehicleRequests';

export type useRegisterVehicleRes = {
  redirect: string;
  doSubmit: () => Promise<void>;
  alert: string;
  passes: Array<passFormInput>;
  setPasses: React.Dispatch<React.SetStateAction<passFormInput[]>>;
  submitLoading: boolean;
  doRemovePass: (
    options?:
      | MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>>
      | undefined,
  ) => Promise<unknown>;
  // registrationDates: { startDate: string, endDate: string } | null,
  t: TFunction;
};

const getVehicle = async (passId: string) =>
  backendClient
    .query<GET_VEHICLE_RES, GET_VEHICLE_VARS>({
      query: GET_VEHICLE,
      fetchPolicy: 'no-cache',
      variables: {
        passId,
      },
    })
    .then(d => newVehicleInfo(d?.data?.getVehicle?.data || {}))
    .catch(err => {
      console.warn(err);
      return newVehicleInfo({});
    });

export function useRegistrationPassesAndVehicles() {
  const [passes, setPasses] = useState<passFormInput[]>([]);
  const { registrationId } = useParams<{ registrationId: string }>();

  const queryRes = useQuery<STEP1_QUERY_RES, STEP1_QUERY_VARS>(STEP1_QUERY, {
    variables: {
      registrationId,
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    onCompleted: async d => {
      const res = await Promise.all(
        (d?.getPassesByRegistration?.data || []).map(async p =>
          newPassInfo({
            ...p,
            vehicle: await getVehicle(p.passId),
          }),
        ),
      );
      setPasses(res);
    },
    onError: err => {
      console.trace();
      console.error(err);
    },
  });

  return {
    ...queryRes,
    passes,
    setPasses,
  };
}

export const useDeletePassAndVehicle = (
  options: MutationHookOptions<
    DELETE_PASS_AND_VEHICLE_RES,
    DELETE_PASS_AND_VEHICLE_VARS
  > = {},
) =>
  useMutation<DELETE_PASS_AND_VEHICLE_RES, DELETE_PASS_AND_VEHICLE_VARS>(
    DELETE_PASS_AND_VEHICLE,
    options,
  );

// MUST always throw human readable error
async function editOrCreatePass(
  pass: passFormInput,
  registrationId: string,
): Promise<string> {
  if (pass.passId) {
    return backendClient
      .mutate<EDIT_PASS_RES, EDIT_PASS_VARS>({
        mutation: EDIT_PASS,
        variables: {
          startDate: pass.startDate,
          endDate: pass.endDate,
          passId: pass.passId,
        },
      })
      .then(res => {
        if (!res.data?.editPass.success)
          throw Error(
            res.data?.editPass.error ||
              'Something went wrong. Your changes may not have been saved',
          );
        return pass.passId;
      })
      .catch(err => {
        console.error(err);
        throw Error('Something went wrong. Your changes may not have been saved');
      });
  } else {
    return backendClient
      .mutate<REGISTER_PASS_RES, REGISTER_PASS_VARS>({
        mutation: REGISTER_PASS,
        variables: {
          passType: 'resident',
          passInfoId: pass.passInfoId,
          registrationId,
          startDate: pass.startDate,
          endDate: pass.endDate,
          addons: pass.addons,
        },
      })
      .then(res => {
        const passId = res.data?.registerPass.data?.[0]?.passId;
        if (!passId || !res.data?.registerPass.success)
          throw Error(
            res.data?.registerPass.error ||
              'Something went wrong. Your changes may not have been saved',
          );
        return passId;
      })
      .catch(err => {
        console.error(err);
        throw Error('Something went wrong. Your changes may not have been saved');
      });
  }
}

const getPrimaryDriverName = (pass: passFormInput): string => {
  if (pass.employee?.firstName || pass.employee?.lastName) {
    return `${pass.employee?.firstName || ''} ${pass.employee?.lastName || ''}`;
  }
  return pass.vehicle.primaryDriverName;
};

// MUST always throw human readable error
async function editOrCreateVehicle(pass: passFormInput, passId: string): Promise<void> {
  if (pass.vehicle.vehicleId) {
    return backendClient
      .mutate<EDIT_VEHICLE_RES, EDIT_VEHICLE_VARS>({
        mutation: EDIT_VEHICLE,
        variables: {
          passInfoId: pass.passInfoId,
          vehicleId: pass.vehicle.vehicleId,
          newVehicleInfo: {
            primaryDriverName: getPrimaryDriverName(pass),
            make: pass.vehicle.make,
            vehicleModel: pass.vehicle.model,
            year: pass.vehicle.year,
            type: pass.vehicle.type,
            color: pass.vehicle.color,
            licensePlate: pass.vehicle.plateNumber,
            fleetNumber: pass.vehicle.fleetNumber,
            licensePlateState: pass.vehicle.licensePlateState,
            licensePrimaryDriver: pass.vehicle.licensePrimaryDriver,
            destination: pass.vehicle.destination,
          },
        },
      })
      .then(res => {
        if (!res.data?.editVehicle.success)
          throw Error(
            res.data?.editVehicle.error ||
              'Something went wrong. Your changes may not have been saved',
          );
      })
      .catch(err => {
        console.error(err);
        throw Error('Something went wrong. Your changes may not have been saved');
      });
  } else {
    return backendClient
      .mutate<REGISTER_VEHICLE_RES, REGISTER_VEHICLE_VARS>({
        mutation: REGISTER_VEHICLE,
        variables: {
          passId,
          primaryDriverName: getPrimaryDriverName(pass),
          make: pass.vehicle.make,
          vehicleModel: pass.vehicle.model,
          year: pass.vehicle.year,
          type: pass.vehicle.type,
          color: pass.vehicle.color,
          licensePlate: pass.vehicle.plateNumber,
          fleetNumber: pass.vehicle.fleetNumber,
          licensePlateState: pass.vehicle.licensePlateState,
          licensePrimaryDriver: pass.vehicle.licensePrimaryDriver,
          destination: pass.vehicle.destination,
        },
      })
      .then(res => {
        if (!passId || !res.data?.registerVehicle.success)
          throw Error(
            res.data?.registerVehicle.error ||
              'Something went wrong. Your changes may not have been saved',
          );
      })
      .catch(err => {
        console.error(err);
        throw Error('Something went wrong. Your changes may not have been saved');
      });
  }
}

export async function submitPassAndVehicleForms(
  passes: passFormInput[],
  registrationId: string,
  stepNumber = 1,
): Promise<unknown> {
  return Promise.all(
    passes.map(pass =>
      editOrCreatePass(pass, registrationId).then(passId =>
        editOrCreateVehicle(pass, passId),
      ),
    ),
  ).then(() =>
    backendClient.mutate<EDIT_STEP_NUMBER_RES, EDIT_STEP_NUMBER_VARS>({
      mutation: EDIT_STEP_NUMBER,
      variables: {
        stepNumber,
        registrationId,
      },
    }),
  );
}
