import { gql, useQuery } from '@apollo/client';
import { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommunityContext } from '../../../../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../../../../common_lib_front/types/backendResponse';
import { backendClient } from '../../../../common_lib_front/utilities/BackendAPI';

export const GET_REGISTRATION_PASSES = gql`
  query GetPassesByRegistration($registrationId: String!) {
    getPassesByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        passId
        passInfoId
        paid
        number
        registrationId
        status
      }
    }
  }
`;
type GET_REGISTRATION_PASSES_VARS = {
  registrationId: string;
};
type GET_REGISTRATION_PASSES_RES = {
  getPassesByRegistration: backendResponse<
    {
      passId: string;
      passInfoId: string;
      paid: string;
      number: number;
      registrationId: string;
      status: string;
    }[]
  >;
};

export const DELETE_PASS = gql`
  mutation Mutation($passId: String!) {
    deletePass(passId: $passId) {
      success
      error
    }
  }
`;
type DELETE_PASS_VARS = {
  passId: string;
};
type DELETE_PASS_RES = {
  deletePass: backendResponse<undefined>;
};

export const REGISTER_PASS = gql`
  mutation Mutation($passType: String!, $registrationId: String!, $passInfoId: String!) {
    registerPass(
      passType: $passType
      registrationId: $registrationId
      passInfoId: $passInfoId
    ) {
      success
      error
      data {
        passId
      }
    }
  }
`;
type REGISTER_PASS_VARS = {
  passType: 'resident';
  registrationId: string;
  passInfoId: string;
};
type REGISTER_PASS_RES = {
  registerPass: backendResponse<
    {
      passId: string;
    }[]
  >;
};

export const GET_PASS_GROUP = gql`
  query getPassGroups($communityId: String!) {
    getPassGroups(communityId: $communityId) {
      success
      error
      data {
        communityId
        passInfoList
        passGroupId
        name
      }
    }
  }
`;

export const GET_ALL_PASS_INFOS = gql`
  query getPassInfosByCommunity($communityId: String!, $complete: Boolean!) {
    getPassInfosByCommunity(communityId: $communityId, complete: $complete) {
      success
      error
      data {
        passInfoId
        communityId
        portal
        complete
        name
        priceInfo {
          price
        }
        description
        educationRequired
      }
    }
  }
`;
export type PassTypeCfg = {
  passInfoId: string;
  communityId: string;
  portal: string;
  complete: boolean;
  name: string;
  description: string;
  priceInfo: { price: number };
  educationRequired: string;
};
type GET_ALL_PASS_INFOS_RES = {
  getPassInfosByCommunity: backendResponse<PassTypeCfg[]>;
};

export type GroupType = {
  communityId: string;
  passInfoList: string[];
  passGroupId: string;
  name: string;
};

export const sleep = async (delayMs: number): Promise<void> =>
  new Promise(resolve => {
    setTimeout(() => resolve(), delayMs);
  });

async function reconcilePassesWithDb(
  registrationId: string,
  selectedQuantities: Record<string, number | undefined>,
  currentDbPassesFetchRes?: GET_REGISTRATION_PASSES_RES['getPassesByRegistration']['data'],
): Promise<void> {
  // fetch pre-existing-passes
  const currentDbPasses =
    currentDbPassesFetchRes ||
    (await backendClient
      .query<GET_REGISTRATION_PASSES_RES, GET_REGISTRATION_PASSES_VARS>({
        query: GET_REGISTRATION_PASSES,
        variables: {
          registrationId,
        },
        fetchPolicy: 'network-only',
      })
      .then(res => res.data.getPassesByRegistration.data)
      .catch(err => {
        console.error(err);
        console.trace();
      }));
  if (!currentDbPasses) throw Error('Could not get passes to reconcile');
  // collect pre-existing pass types
  const mapPassTypesToPassIds: Record<string, string[] | undefined> = {};
  currentDbPasses.forEach(pass => {
    const pIds = mapPassTypesToPassIds[pass.passInfoId] || [];
    pIds.push(pass.passId);
    if (!mapPassTypesToPassIds[pass.passInfoId]) {
      mapPassTypesToPassIds[pass.passInfoId] = pIds;
    }
  });
  // add or delete passes
  Promise.allSettled(
    Object.entries(selectedQuantities).map(([passInfoId, quantity]) => {
      if (!Number.isInteger(quantity) || typeof quantity !== 'number' || quantity < 0) {
        console.error(`Unexpected quantity ${quantity}`);
        console.trace();
        throw Error(`Unexpected quantity ${quantity}`);
      }
      const quantityDiff = quantity - (mapPassTypesToPassIds[passInfoId]?.length || 0);
      if (quantityDiff > 0) {
        return Promise.allSettled(
          Array(quantityDiff)
            .fill(true)
            .map(() => {
              backendClient
                .mutate<REGISTER_PASS_RES, REGISTER_PASS_VARS>({
                  mutation: REGISTER_PASS,
                  variables: {
                    registrationId,
                    passType: 'resident',
                    passInfoId,
                  },
                })
                .catch(err => {
                  console.error(err);
                  console.trace();
                });
            }),
        );
      } else if (quantityDiff < 0) {
        // quantity diff is negative
        // select passes to delete. Select from back of list
        const passIdsToDelete =
          mapPassTypesToPassIds[passInfoId]?.slice(quantityDiff) || [];
        return Promise.allSettled(
          passIdsToDelete.map(passId => {
            backendClient
              .mutate<DELETE_PASS_RES, DELETE_PASS_VARS>({
                mutation: DELETE_PASS,
                variables: {
                  passId,
                },
              })
              .catch(err => {
                console.error(err);
                console.trace();
              });
          }),
        );
      }
    }),
  ).catch(err => {
    console.error(err);
    console.trace();
  });
}

type usePassSelectionRes = {
  passGroups: GroupType[];
  passTypes: PassTypeCfg[];
  passTypeSelectQuantities: Record<string, number | undefined>;
  setQuantity: (passInfoId: string, quantity: number) => void;
  incQuantity: (passInfoId: string, amount: number) => void;
  // saveSelected: () => void;
  // removeSelected: () => void;
  // isSelected: () => boolean;
  reconcilePassesWithDb: () => Promise<void>;
};

export default function usePassSelection(): usePassSelectionRes {
  const { communityId } = useContext(CommunityContext);
  const { registrationId } = useParams<{ registrationId?: string }>();
  const [passTypeSelectQuantities, setPassTypeSelectQuantities] = useState<
    Record<string, number | undefined>
  >({});
  // const [passTypes, setPassTypes] = useState<PassTypeCfg[]>([]);
  // const [selectedPassTypes, setSelectedPassTypes] = useState<PassTypeCfg[]>(
  //   store.getState().selectedPassTypes,
  // );

  const { data: getRegistrationPassesData } = useQuery<
    GET_REGISTRATION_PASSES_RES,
    GET_REGISTRATION_PASSES_VARS
  >(GET_REGISTRATION_PASSES, {
    variables: {
      registrationId: registrationId || '',
    },
    skip: !registrationId,
    onCompleted: d => {
      if (d.getPassesByRegistration.data) {
        const newPassCts: typeof passTypeSelectQuantities = {};
        d.getPassesByRegistration.data.forEach(p => {
          newPassCts[p.passInfoId] = (newPassCts[p.passInfoId] || 0) + 1;
        });
        setPassTypeSelectQuantities(newPassCts);
      }
    },
  });

  const { data: groupData } = useQuery(GET_PASS_GROUP, {
    fetchPolicy: 'network-only',
    variables: {
      communityId,
    },
  });

  const { data: passInfoData } = useQuery<GET_ALL_PASS_INFOS_RES>(GET_ALL_PASS_INFOS, {
    fetchPolicy: 'network-only',
    variables: {
      communityId,
      complete: true,
    },
    // onCompleted: async allPassData => {
    //   try {
    //     const passList =
    //       allPassData?.getPassInfosByCommunity.data?.filter(
    //         ele => ele.portal === 'resident',
    //       ) || [];
    //     setPassTypes(
    //       passList.map((passType: PassTypeCfg) => {
    //         const selected = selectedPassTypes?.filter(
    //           item => item.passInfoId === passType.passInfoId,
    //         )[0];
    //         const updatedObject = {
    //           ...passType,
    //           quantity: Math.abs(selected?.quantity || 0),
    //         };
    //         passType = updatedObject;
    //         return passType;
    //       }),
    //     );
    //   } catch (error: any) {
    //     console.log(error);
    //   }
    // },
  });

  const setQuantity = (passInfoId: string, quantity: number): void => {
    if (Number.isInteger(quantity)) {
      setPassTypeSelectQuantities(prev => ({
        ...prev,
        [passInfoId]: quantity,
      }));
    }
  };

  const incQuantity = (passInfoId: string, amount: number): void => {
    if (Number.isInteger(amount)) {
      setPassTypeSelectQuantities(prev => ({
        ...prev,
        [passInfoId]: (prev[passInfoId] || 0) + amount,
      }));
    }
  };

  // const saveSelected = () => {
  //   const lstIds = passTypes
  //     .filter((passType: PassTypeCfg): boolean => passType.quantity > 0)
  //     .map(obj => obj.passInfoId);
  //   store.dispatch({
  //     type: 'SAVE_SELECTED',
  //     payload: {
  //       selectedPasses: passTypes.filter(
  //         (passType: PassTypeCfg): boolean => passType.quantity > 0,
  //       ),
  //       // documentsConfig: result,
  //     },
  //   });
  //
  //   setSelectedPassTypes(store.getState().selectedPassTypes);
  // };

  // const removeSelected = () => {
  //   store.dispatch({
  //     type: 'REMOVE_SELECTED',
  //     payload: [],
  //   });
  // };

  // const isSelected = (): boolean =>
  //   passTypes.filter((passType: PassTypeCfg): boolean => passType.quantity > 0).length !==
  //   0;

  const reconcilePassesMemoized = useCallback(
    () =>
      reconcilePassesWithDb(
        registrationId || '',
        passTypeSelectQuantities,
        // getRegistrationPassesData?.getPassesByRegistration.data,
      ),
    [registrationId, passTypeSelectQuantities],
  );

  return {
    passGroups: groupData?.getPassGroups.data,
    passTypes:
      passInfoData?.getPassInfosByCommunity.data?.filter(
        pt => pt.portal === 'resident',
      ) || [],
    passTypeSelectQuantities,
    setQuantity,
    incQuantity,
    // saveSelected,
    // removeSelected,
    // isSelected,
    reconcilePassesWithDb: reconcilePassesMemoized,
  };
}
