import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import GenericButton from '../../../../common_lib_front/components/genericButton/genericButton';
import PassSummaryCollection from '../../../../common_lib_front/components/passSummary/passSummaryCollection';
import PaymentInfoForm from '../../../../common_lib_front/components/paymentInfoForm/paymentInfoForm';
import useOrderPrice from '../../../../common_lib_front/hooks/useOrderPrice';
import ResidentInformationReview from '../../../../components/residentInformationReview/residentInformationReview';
import ResidentPassHeader from '../../../../components/residentPassHeader/residentPassNavHeader';
import style from './secureCheckout.module.css';
import useSecureCheckout from './useSecureCheckout';

export default function SecureCheckout(): ReactElement {
  const {
    loading,
    userInfo,
    registrationId,
    passes,
    redirect,
    submitHandler,
    selectedPayment,
    setSelectedPayment,
  } = useSecureCheckout();

  const { totalPrice } = useOrderPrice(passes);

  const { t } = useTranslation();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const isFree = totalPrice === 0;

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <ResidentPassHeader
          activeNum={3}
          title={`${t('Step 3')}: ${t('Secure Checkout')}`}
          subtitle={t('')}
        />
        <div className={style.flexBox}>
          <div className={style.boxLeft}>
            <ResidentInformationReview
              data={userInfo}
              editLink={`/resident/get-passes/resident-pass/${registrationId}`}
              registrationId={registrationId}
            />
          </div>
          <div className={style.boxRight}>
            <PassSummaryCollection
              showEditBtn
              showVehicleInfo
              showTotal
              data={passes}
              editLink={`/resident/get-passes/resident-pass/${registrationId}/vehicle`}
            />
            {!isFree && (
              <PaymentInfoForm
                selectedPayment={selectedPayment}
                setSelectedPayment={setSelectedPayment}
                passInfoIds={passes.map(p => p.passInfoId)}
              />
            )}
          </div>
        </div>
        <div className={style.btn}>
          <GenericButton
            size="large"
            color="blue"
            title={loading ? t('Please Wait...') : t('Confirm Purchase')}
            disabled={loading}
            clickHandler={() => {
              submitHandler(isFree);
            }}
          />
        </div>
      </div>
    </div>
  );
}
