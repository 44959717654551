import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import GenericAlert from '../../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../../common_lib_front/components/genericButton/genericButton';
import LoadingDiamonds from '../../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import ResidentPassHeader from '../../../../components/residentPassHeader/residentPassNavHeader';
import style from './passSelection.module.css';
import usePassSelection, { sleep } from './usePassSelection';

export default function PassSelection(): ReactElement {
  const {
    passGroups,
    passTypes,
    passTypeSelectQuantities,
    setQuantity,
    incQuantity,
    reconcilePassesWithDb,
  } = usePassSelection();
  const { registrationId } = useParams<{ registrationId: string }>();
  const [redirect, setRedirect] = useState<string>('');
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const doSubmit = async () => {
    try {
      setSubmitLoading(true);
      // saveSelected();
      await reconcilePassesWithDb();
      // The next page is being loaded before the database update is complete.
      // This causes the incorrect number of passes to be fetched and rendered
      await sleep(1000);
      setRedirect(`/resident/get-passes/resident-pass/${registrationId}/vehicle`);
    } catch (err) {
      console.log(err);
      console.trace();
    } finally {
      setSubmitLoading(false);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <ResidentPassHeader
          title={t('Pass Selection')}
          subtitle={t('Please select passes below')}
        />

        {passGroups?.length === 0 && (
          <GenericAlert
            title="No passes for this community are available at the moment"
            color="red"
          />
        )}
        <div className={style.passContainer}>
          {passTypes.map(passInfo => (
            <div className={style.passInfo} key={passInfo.passInfoId}>
              <div className={style.passInfoName}>
                <h4 className={style.passType}>{t('Pass Type:')}</h4>
                <h4 className={style.passName}>{passInfo.name}</h4>
                <div className={style.passDescriptionBox}>
                  <div className={style.passDescription}>{passInfo.description}</div>
                </div>
              </div>
              <div className={style.priceInfo}>
                <div className={style.priceInfoLabel}>
                  {t('Price: ')}
                  <span className={style.price}>
                    ${passInfo?.priceInfo?.price || 0}
                    .00
                  </span>
                </div>
              </div>
              <div className={style.passNumberBox}>
                <label className={style.label} htmlFor="number-select">
                  {t('Number of Passes')}
                  <div className={style.numberInputBox}>
                    <button
                      className={style.btnLeft}
                      onClick={() => incQuantity(passInfo.passInfoId, -1)}
                    >
                      -
                    </button>
                    <input
                      id="number-select"
                      type="number"
                      className={style.numberInput}
                      value={passTypeSelectQuantities[passInfo.passInfoId] || 0}
                      onChange={event =>
                        setQuantity(passInfo.passInfoId, parseInt(event.target.value, 10))
                      }
                    />
                    <button
                      className={style.btnRight}
                      onClick={() => incQuantity(passInfo.passInfoId, 1)}
                    >
                      +
                    </button>
                  </div>
                </label>
              </div>
            </div>
          ))}
        </div>

        <div className={style.btn}>
          {submitLoading ? (
            <div
              style={{
                marginBottom: '50px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <LoadingDiamonds />
            </div>
          ) : (
            <GenericButton
              size="large"
              color="blue"
              title={t('Continue')}
              disabled={
                submitLoading ||
                Object.values(passTypeSelectQuantities).every(
                  val => val === 0 || val === undefined,
                )
              }
              clickHandler={doSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
}
