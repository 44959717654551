import { gql } from '@apollo/client';
import { backendResponse } from '../../../../common_lib_front/types/backendResponse';
import { PassStatuses } from '../../../../common_lib_front/types/passInfo';

export const STEP1_QUERY = gql`
  query Step1($registrationId: String!) {
    getRegistration(registrationId: $registrationId) {
      success
      error
      data {
        registrationId
        complete
        renew
      }
    }
    getPassesByRegistration(registrationId: $registrationId) {
      success
      error
      data {
        passId
        passInfoId
        number
        externalCredentialNumber
        passType
        startDate
        endDate
        status
        addons
      }
    }
  }
`;
export type STEP1_QUERY_VARS = {
  registrationId: string;
};
export type STEP1_QUERY_RES = {
  getRegistration: backendResponse<
    {
      registrationId: string;
      complete: boolean;
      renew: boolean;
    }[]
  >;

  getPassesByRegistration: backendResponse<
    {
      passId: string;
      passInfoId: string;
      number: string;
      passType: string;
      startDate: string;
      endDate: string;
      status: PassStatuses;
      addons: string[];
      // vehicle?: {
      //   vehicleId: string;
      //   make: string;
      //   vehicleModel: string;
      //   year: number;
      //   type: string;
      //   color: string;
      //   licensePlate: string;
      //   fleetNumber: string;
      //   licensePlateState: string;
      //   primaryDriverName: string;
      //   licensePrimaryDriver: string;
      // } | null;
    }[]
  >;
};

export type SAVE_VEHICLE_VARS = {
  make: string;
  vehicleModel: string;
  year?: number;
  type: string;
  color: string;
  licensePlate: string;
  fleetNumber: string;
  passId: string;
  licensePlateState?: string;
  primaryDriverName?: string;
  licensePrimaryDriver?: string;
};

export const DELETE_PASS_AND_VEHICLE = gql`
  mutation DeletePassAndVehicle($passId: String!, $vehicleId: String!) {
    deletePass(passId: $passId) {
      success
      error
    }
    deleteVehicle(vehicleId: $vehicleId) {
      success
      error
    }
  }
`;
export type DELETE_PASS_AND_VEHICLE_VARS = {
  passId: string;
  vehicleId: string;
};
export type DELETE_PASS_AND_VEHICLE_RES = {
  deletePass: backendResponse<undefined>;
  deleteVehicle: backendResponse<undefined>;
};

export const GET_PRICE_INFO = gql`
  query GetPriceInfo($passInfoId: String!) {
    getPriceInfo(passInfoId: $passInfoId) {
      success
      error
      data {
        passInfoId
        isFree
        price
        chargePerDay
        discount
        discountDuration
        discountPrice
        discountPurchasedPasses
        discountPurchasedPassesRanges
        discountPurchasedPassesPrices
        addons {
          name
          isFree
          price
        }
      }
    }
  }
`;

export const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId) {
      success
      error
      data {
        vehicleId
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        isRental
        licensePlateState
        primaryDriverName
        licensePrimaryDriver
      }
    }
  }
`;
export type GET_VEHICLE_VARS = {
  passId: string;
};
export type GET_VEHICLE_RES = {
  getVehicle: backendResponse<{
    vehicleId: string;
    make: string;
    vehicleModel: string;
    type: string;
    color: string;
    licensePlate: string;
    fleetNumber: string;
    isRental: boolean;
    licensePlateState: string;
    primaryDriverName: string;
    licensePrimaryDriver: string;
  }>;
};

export const EDIT_STEP_NUMBER = gql`
  mutation EditRegistrationStepNumber($stepNumber: Float!, $registrationId: String!) {
    editRegistrationStepNumber(
      stepNumber: $stepNumber
      registrationId: $registrationId
      registrationType: "residentPass"
    ) {
      success
      error
    }
  }
`;
export type EDIT_STEP_NUMBER_VARS = {
  stepNumber: number;
  registrationId: string;
};
export type EDIT_STEP_NUMBER_RES = {
  editRegistrationStepNumber: backendResponse<undefined>;
};

export const REGISTER_PASS = gql`
  mutation RegisterPass(
    $passType: String!
    $passInfoId: String!
    $registrationId: String!
    $startDate: DateTime!
    $endDate: DateTime!
    $addons: [String!]
  ) {
    registerPass(
      passType: $passType
      registrationId: $registrationId
      passInfoId: $passInfoId
      status: $status
      startDate: $startDate
      endDate: $endDate
      addons: $addons
    ) {
      success
      error
      data {
        passId
      }
    }
  }
`;
export type REGISTER_PASS_VARS = {
  passType: 'resident';
  passInfoId: string;
  registrationId: string;
  startDate: string | Date;
  endDate: string | Date;
  addons?: string[];
};
export type REGISTER_PASS_RES = {
  registerPass: backendResponse<
    {
      passId: string;
    }[]
  >;
};

export const REGISTER_VEHICLE = gql`
  mutation RegisterVehicle(
    $passId: String!
    $make: String
    $vehicleModel: String
    $year: Float
    $type: String
    $color: String
    $licensePlate: String
    $fleetNumber: String
    $licensePlateState: String
    $primaryDriverName: String
    $licensePrimaryDriver: String
    $destination: String
  ) {
    registerVehicle(
      passId: $passId
      make: $make
      vehicleModel: $vehicleModel
      year: $year
      type: $type
      color: $color
      licensePlate: $licensePlate
      fleetNumber: $fleetNumber
      licensePlateState: $licensePlateState
      primaryDriverName: $primaryDriverName
      licensePrimaryDriver: $licensePrimaryDriver
      destination: $destination
    ) {
      success
      error
    }
  }
`;
export type REGISTER_VEHICLE_VARS = {
  passId: string;
  make?: string;
  vehicleModel?: string;
  year?: number;
  type?: string;
  color?: string;
  licensePlate?: string;
  fleetNumber?: string;
  licensePlateState?: string;
  primaryDriverName: string;
  licensePrimaryDriver?: string;
  destination?: string;
};
export type REGISTER_VEHICLE_RES = {
  registerVehicle: backendResponse<undefined>;
};

export const EDIT_PASS = gql`
  mutation EditPass($endDate: DateTime!, $startDate: DateTime!, $passId: String!) {
    editPass(endDate: $endDate, startDate: $startDate, passId: $passId) {
      success
      error
    }
  }
`;
export type EDIT_PASS_VARS = {
  endDate: string | Date;
  startDate: string | Date;
  passId: string;
};
export type EDIT_PASS_RES = {
  editPass: backendResponse<undefined>;
};

export const EDIT_VEHICLE = gql`
  mutation EditVehicle(
    $passInfoId: String!
    $newVehicleInfo: ActuallyInputTypeVehicleInput!
    $vehicleId: String!
  ) {
    editVehicle(
      passInfoId: $passInfoId
      newVehicleInfo: $newVehicleInfo
      vehicleId: $vehicleId
    ) {
      success
      error
    }
  }
`;
export type EDIT_VEHICLE_VARS = {
  passInfoId: string;
  vehicleId: string;
  newVehicleInfo: {
    make?: string;
    vehicleModel?: string;
    year?: number;
    type?: string;
    color?: string;
    licensePlate?: string;
    fleetNumber?: string;
    licensePlateState?: string;
    primaryDriverName: string;
    licensePrimaryDriver?: string;
    destination?: string;
  };
};
export type EDIT_VEHICLE_RES = {
  editVehicle: backendResponse<undefined>;
};
